import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import ScrollspyNav from 'react-scrollspy-nav';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { Row, Accordion, Tab, Tabs, Col } from 'react-bootstrap';
import { AshCharacter } from '../../../modules/ash/components/ash-character';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const AshGuidesBossSepho: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ash-guide'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/ash-echoes/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Boss Challenge: Sephonis (300%)</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ash/categories/category_event_2.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Boss Challenge: Sephonis (300%)</h1>
          <h2>
            A dedicated guide for the Boss Challenge: Sephonis in Ash Echoes.
            Beat the 300% difficulty with ease with our guide!
          </h2>
          <p>
            Last updated: <strong>09/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Introduction" />
              <p>
                For the generic Voyagers and Everlasting Shadows event guide, go
                here as in this guide we will only focus on the Boss Challenge:
                Sephonis part of the event.
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="Voyagers and Everlasting Shadows"
                  link="/ash-echoes/guides/voyagers-and-everlasting-shadows"
                  image={
                    <StaticImage
                      src="../../../images/ash/categories/category_event_1.webp"
                      alt="Voyagers and Everlasting Shadows"
                    />
                  }
                />
              </Row>
              <SectionHeader title="Boss Challenge: Sephonis" />
              <StaticImage
                src="../../../images/ash/event/sephonis_1.webp"
                alt="Guides"
              />
              <p>
                Unlocked after going through the event story, you can face
                Sephonis and defeat her multiple times as she slowly but surely
                powers up before becoming almost unrecognizable once the highest
                difficulty is reached.
              </p>
              <p>
                <strong>DISCLAIMER</strong>: Let us preface this guide by saying
                that the longer part of it was done with the{' '}
                <strong>300%</strong> difficulty in mind and with relatively low
                specs, which increased the difficulty even more.
              </p>
              <p>
                For any player who’s looking into a more relaxed and chill
                experience when going through the Boss, a difficulty of{' '}
                <strong>200% to 250%</strong> is much more realistic and
                achievable, and for you we’ve prepared a much smaller and more
                compact guide that gives a more streamlined overview of the Boss
                without going deep into details.
              </p>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Boss Challenge Lite Edition" />
              <p>
                Here’s a little recipe we’ve cooked just for you, light on
                details and free of long winded explanations.
              </p>
              <p>
                For a 4 Echomancers, <strong>200% Boss</strong>, you need:
              </p>
              <ul className="bigger-margin">
                <li>
                  An <strong>Engraving Rating of about A20-22</strong> depending
                  on the Stat spread (the less Defensive, the higher it needs to
                  be);
                </li>
                <li>
                  You don’t need more than 400-500 DEF and VIT, so get those
                  first during your Nexus Run before fully investing in ATK and
                  MST;
                </li>
                <li>
                  <strong>Inspire stacks</strong>, the whole 20 if you can.
                  They’re the seasoning of your team and can make every damage
                  dealer feel way stronger after you stack enough of them. Truly
                  the spice of life, as they say;
                </li>
                <li>
                  A Tank next to the boss, as long as the Echomancer has sources
                  of DR or Defensive Skills{' '}
                  <strong>even 4 star Tanks can work just fine</strong>
                  (They finally get to feel like the protagonists of their
                  story);
                </li>
                <li>
                  A touch of Healing, just enough to keep you energized.{' '}
                  <AshCharacter
                    slug="freda"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <AshCharacter
                    slug="longqing"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  <AshCharacter
                    slug="zodivin-azure"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  are all top picks. You can even keep a{' '}
                  <AshCharacter
                    slug="su-xiao"
                    mode="inline"
                    showLabel
                    showTags
                    showIcon
                    enablePopover
                  />{' '}
                  with you in case you need an instant refresher with a Full
                  Heal on Ult and a one time Rescue of one of your Echomancers
                  should you need it;
                </li>
                <li>
                  You can run{' '}
                  <strong>
                    around the map, forever dodging the Marionette
                  </strong>
                  (what a turtle!). If you’ve got AoEs, might as well use them
                  on both Sephonis and Aria (that’s the Marionette!);
                </li>
                <li>
                  When you see something Gold colored under your feet, it’s time
                  to pack your stuff and settle in a new sub-section;
                </li>
                <li>
                  Sephonis releases many tiny butterflies from her sleeves like
                  a magician (seriously how many of them did she hide?!). They
                  circle around the map at a slow pace so{' '}
                  <strong>either destroy them</strong>
                  with Interception Skills <strong>or move</strong> your
                  Echomancers around, they can’t reach the edge of the
                  battlefield with their tiny wings;
                </li>
                <li>
                  In Phase 2, Sehonis uses the multi-clonage technique and{' '}
                  <strong>
                    sends 3 Clones to fight, who are secretly ticking time
                    bombs!
                  </strong>{' '}
                  a Good Ult or 2, with a couple of Active Skills on top will do
                  wonders;
                </li>
                <li>
                  Sephonis is back, but she doesn’t seem to be doing all that
                  well? It’s time to give her a good dose of Burst to finish
                  things off with a bang!
                </li>
              </ul>
              <p>
                For the <strong>250%</strong> version, a few things need to be
                updated:
              </p>
              <ul>
                <li>
                  Sephonis has turned up the heat by quite a bit, so you need to
                  be a bit sturdier to resist the corrosive Damage over Time
                  (DoT) from her Corrosive Force modifier. Try to aim for
                  500-600 DEF or VIT and 400-500 of the other, then once again
                  all in on ATK, grabbing some MST along the way if it comes in
                  a big amount at once.
                  <strong> A22-24 is the likely Engraving Rating</strong>
                  you’re going to get with Memory Traces at level40 or so;
                </li>
                <li>
                  Since the overall damage you’re taking has increased, your
                  lower rarity Tanks might no longer be able to withstand the
                  onslaught from Sephonis, and that may also be the case for the
                  rest of your Team. For that reason, if you have access to
                  stronger options like the 6 star Tanks now is a good time to
                  finally bring them out. It can also be a great help to{' '}
                  <strong>
                    bring a second Healer and have 2 of them active
                  </strong>{' '}
                  whenever the incoming damage is too much;
                </li>
                <li>
                  Sephonis also breaks out of her Cocoon, growing Golden Wings
                  from her back and giving her <strong>Basic Attacks</strong>{' '}
                  (that’s the Alpha Spirit Butterfly ability){' '}
                  <strong>Penetration properties</strong>, making them pass
                  right through your Tank to deal damage to everyone in a
                  straight line, from Sephonis all the way to the end of the
                  Battlefield. You guessed it, you don’t want anyone standing
                  right behind your Tank because they might be able to shrug
                  this attack off, but your Rangers and Supports can’t.
                </li>
              </ul>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="200% and 250% Team Examples" />
              <h5>Team #1 - 200%</h5>
              <StaticImage
                src="../../../images/ash/event/sephonis_2.webp"
                alt="Guides"
              />
              <p>
                Your eyes are not seeing wrong, this is indeed a Fire team that
                doesn’t have 2 Lightning Echomancers but still plays the SSR
                Memory Trace ‘Remaining Friends’, fully missing its main draw:
                the Inspire stacks when 2 Lightning Echomancers are in play.
              </p>
              <p>
                This build dates from the first days of the CBT and after
                replicating it, yes it is perfectly capable of beating 200%
                difficulty.
              </p>
              <p>
                There is very little synergy to speak of here, all the Traces
                can be changed as you want, as long as you can get 15+ stacks of
                Inspire. The DEF is low, admittedly dangerously so, but with a
                Seed 5{' '}
                <AshCharacter
                  slug="freda"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                the Healing output was enough to keep everyone alive. With the
                revamped Nexus mode, you can easily dip into DEF and MST and
                accept a small loss of ATK in exchange.
              </p>
              <h5>Team #2 - 250%</h5>
              <StaticImage
                src="../../../images/ash/event/sephonis_3.webp"
                alt="Guides"
              />
              <p>
                Pretty neat 250% cozy team, the entire synergy here lies with
                the triple VIT Stacking, ‘Managing Marks’ gives ATK based on a
                percentage of your VIT once the latter has reached 1000 or
                above, ‘Behind The Curtains’ offers healing whenever your
                Echomancers get hit once your VIT crosses the 750 threshold and
                ‘Sweet Dreams’ is, well, it’s a VIT Trace with little to no
                synergy with the rest of the build. Every Trace can be replaced
                aside from the first two mentioned, giving you a lot of freedom
                and many potential upgrades.
              </p>
              <p>
                The only change in Echomancers is{' '}
                <AshCharacter
                  slug="cyros"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                , Reid’s damage output being a little lacking for prolonged
                fights.
              </p>
              <h5>Video</h5>
              <p>Here's a video showcasing a 250% clear with the team:</p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="Y6zhMtbLuqc" />
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Key Mechanics" />
              <p>
                In this section we take a look at all the interesting ways
                Sephonis can destroy your Echomancers, especially at high
                difficulty and yeah, there are plenty of them, so buckle up!{' '}
              </p>
              <p>
                Before we dive in, it’s good to know that all of Sephonis’
                powerful damage abilities occur multiple times per fight - with
                the amount depending on how long it takes you to take her down.
                For that reason, you should never spend all of your Active
                Skills and Ultimates on a single mechanic.
              </p>
              <h5>Damage Reduction and Revive</h5>
              <p>
                Starting from <strong>165%</strong> difficulty, Sephonis gains a
                10-60% Damage Reduction Passive depending on the level of
                difficulty you select. As long as the Marionette is part of the
                fight, Sephonis takes greatly reduced damage. This Damage
                Reduction takes effect for the first half of the fight, and then
                is momentarily dispelled by Stunning Aria during the second
                Phase. Make sure to have your offensive buffs and strongest
                abilities ready to exploit these brief windows to the fullest.
              </p>
              <p>
                Furthermore, starting from <strong>140%</strong> difficulty,
                Sephonis gains a one time Revive + Full Heal which leads into
                Phase 2. This is especially relevant for resource management, as
                you need to keep enough Skill uses for both Phases of the
                encounter. At higher difficulties, Sephonis gains a new ability
                during Phase 2.
              </p>
              <Accordion defaultActiveKey={['0']} alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Corrosive Force</Accordion.Header>
                  <Accordion.Body>
                    <p>
                      Once you reach <strong>150%</strong> difficulty, every few
                      seconds, Sephonis performs a powerful map-wide AoE that
                      deals high damage to your Echomancers. This attack
                      primarily acts as a survivability check, dying to it means
                      that you’re likely missing adequate VIT and/or DEF stats,
                      or that your healing output isn’t enough. No two ways to
                      work around this attack, you must build some VIT and DEF
                      during your Nexus run and always have a healer - or even
                      two at times - on the field as long as you’re not entering
                      the final Burst phase to take the Boss down. Damage
                      Reduction (DR) Skills are also very much welcome, as
                      healing alone is unlikely to keep you alive at the highest
                      difficulties.
                    </p>
                    <p>
                      <strong>Key Echomancers:</strong>
                    </p>
                    <ul className="bigger-margin">
                      <li>
                        <AshCharacter
                          slug="freda"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        <AshCharacter
                          slug="zodivin-azure"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        are both great at Healing and providing DR with high
                        uptime, although Freda’s ability to switch to a much
                        more offensive style of buffing gives her the edge;
                      </li>
                      <li>
                        <AshCharacter
                          slug="longqing"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        is a great source of punctual DR as well as Burst
                        Healing, and while her Heal over Time (HoT) might not be
                        enough to solo Heal she is clutch to mitigate the damage
                        early on;
                      </li>
                      <li>
                        <AshCharacter
                          slug="lorelle"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        provides DR at all times to the team as long as she
                        remains in Defense Mode, so switch her to it at the
                        start of the battle and keep it this way for the entire
                        fight;
                      </li>
                      <li>
                        An honorable Mention goes to{' '}
                        <AshCharacter
                          slug="caroline"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        who can provide Healing thanks to his ‘Goodspeed
                        Potion’. This helps everyone recover a percentage of
                        their max HP every few seconds, except a few Echomancers
                        that do not attack, like Freda.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    The Alpha Spirit Butterfly
                  </Accordion.Header>
                  <Accordion.Body>
                    <StaticImage
                      src="../../../images/ash/event/sephonis_4.webp"
                      alt="Guides"
                    />
                    <p>
                      For Sephonis’ Basic Attack, she sends a volley of 3 huge
                      butterflies to the closest Echomancer in range, dealing
                      little damage but becomes more and more dangerous as the
                      difficulty is raised:
                    </p>
                    <ul>
                      <li>
                        The <strong>Flutter</strong> modifier makes it stronger,
                        but not so strong that it threatens your Tanks. Still,
                        the attack becomes fairly threatening to the squishier
                        Strikers and is definitely something your Ranged
                        Echomancers don’t want to deal with on top of all the
                        other damage sources;
                      </li>
                      <li>
                        Starting from <strong>250%</strong> difficulty,{' '}
                        <strong>Break The Cocoon</strong> gives Penetration
                        properties to the Alpha Butterflies, allowing them to
                        pass through your Echomancers. This is a big deal if
                        you’re playing many Melee Echomancers as you might often
                        reposition your Strikers behind your Tanks during
                        Migration or to avoid the Mines;
                      </li>
                      <li>
                        Unlocked at <strong>275%</strong> difficulty,{' '}
                        <strong>Withering</strong> is another modifier that
                        massively reinforces Alpha Butterfly, as it adds a
                        permanent, infinitely stacking Damage Increase debuff to
                        any Echomancer hit by the Alpha Butterfly. While the
                        debuff isn’t noticeable early on, it makes it near
                        impossible for any non-Tank Echomancer to survive as the
                        primary Target of Sephonis for more than a minute or
                        two, especially once you factor in the final two levels
                        of the <strong>New Feathers</strong> (the overall Attack
                        Increase) modifier at <strong>255% and 280%</strong>{' '}
                        difficulty.
                      </li>
                    </ul>
                    <p>
                      <strong>Key Echomancers:</strong>
                    </p>
                    <p>
                      You should bring at least one Tank, either a Bulwark or a
                      Vanguard, to make them the primary target of Sephonis.As
                      after a few minutes of fighting at high difficulty, the
                      damage debuff would melt any Echomancer without access to
                      personal Defensive buffs.
                    </p>
                    <p>
                      <AshCharacter
                        slug="lorelle"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      ,{' '}
                      <AshCharacter
                        slug="mo-hongxiu"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      , or even{' '}
                      <AshCharacter
                        slug="rodney"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      (1) can serve as main Tanks, and bench options can include
                      Tian Ruo or Yuqi (who is surprisingly good at dealing with
                      the Marionette).
                    </p>
                    <p>
                      (1).{' '}
                      <AshCharacter
                        slug="rodney"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      and other lower rarity Tanks require heavy investment in
                      VIT and DEF Engraving Stats during your Nexus run and
                      generally bring less to the team than their higher rarity
                      counterparts.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Aria, the Marionette</Accordion.Header>
                  <Accordion.Body>
                    <StaticImage
                      src="../../../images/ash/event/sephonis_5.webp"
                      alt="Guides"
                    />
                    <p>
                      At the start of the battle and multiple times throughout
                      the fight, Sephonis sends Aria at one of your Ranged
                      Echomancers (placing a Melee Echomancer in the back and
                      having your Ranged Echomancers closer to the Boss{' '}
                      <strong>does not</strong>
                      change the targeting priority and only makes your Ranged
                      Units easier to reach for the Marionette). Aria then
                      slowly floats in the direction of your Echomancer and
                      should she catch them, they are inflicted with an
                      extremely long Stun that makes them unable to act in any
                      way with damage on top. To deal with this mechanic, a few
                      options available:
                    </p>
                    <ul>
                      <li>
                        You can continuously reposition your Echomancer to keep
                        a certain distance;
                      </li>
                      <li>
                        You can use a Barrier with Movement restrictions
                        properties or use Displacement like Pull and Push Skills
                        to hinder Aria’s progress.
                      </li>
                    </ul>
                    <p>
                      In both cases, it’s highly recommended to use AoE Skills
                      or Echomancers with AoE Basic Attacks to deplete Aria’s
                      HP, which is based on the Hit Count of your attack, or its
                      ‘strength’. An Ultimate for example deals 10 damage to
                      Aria while an Active Skill deals 5 (most skills only
                      register a hit on Aria once). Upon reaching 0HP, Aria
                      becomes stunned for a while.
                    </p>
                    <p>
                      However, do not worry too much, as failure to Stun Aria
                      before she reaches your Echomancer does not result in
                      Instant Death. Instead, you have another{' '}
                      <strong>25 seconds</strong> to disable/displace her before
                      your Echomancer is put out of commission.
                    </p>
                    <p>
                      Notes: The Drones summoned by the Terminal can also target
                      Aria, but for that to happen. Aria needs to be closer to
                      your Terminal than Sephonis. Thus if you want to use this
                      method you must first reposition your Echomancer all the
                      way down, right next to your Terminal.
                    </p>
                    <p>
                      Tip: Beating Aria with a Corrosive Blast from the Terminal
                      or Lorelle’s Ultimate removes her from the fight, which is
                      both a blessing and a curse. The fight becomes easier with
                      less mechanics to pay attention to, but on the other hand
                      forcing her out of the fight early can prevent you from
                      achieving the ‘Stun the Marionette 4 times’ bonus
                      condition.
                    </p>
                    <p>
                      <strong>Key Echomancers:</strong>
                    </p>
                    <p>
                      Outside of Echomancers with ample AoE damage like{' '}
                      <AshCharacter
                        slug="tong"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      (whose Active Skill deals not just one but three instances
                      of 5 damage!){' '}
                      <AshCharacter
                        slug="roar"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      <AshCharacter
                        slug="cyros"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      or even{' '}
                      <AshCharacter
                        slug="felicio"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      to a much lesser extent (if you can keep Aria between him
                      and Sephonis), here are some of the more relevant
                      Echomancers you can use to disrupt Aria:
                    </p>
                    <ul className="bigger-margin">
                      <li>
                        For Echomancers with strong path blocking Barriers, you
                        can opt for:
                      </li>
                      <ul>
                        <li>
                          <AshCharacter
                            slug="roar"
                            mode="inline"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />{' '}
                          , a great pick whose Barrier will by itself deal
                          significant damage to Aria on top of her AoE Basic
                          Attacks.
                        </li>
                        <li>
                          <AshCharacter
                            slug="mo-hongxiu"
                            mode="inline"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />{' '}
                          , a smaller Barrier with less uses but is relevant
                          nonetheless.
                        </li>
                        <li>
                          Although weaker than the Barriers,{' '}
                          <AshCharacter
                            slug="sephonis"
                            mode="inline"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />{' '}
                          <AshCharacter
                            slug="aceo"
                            mode="inline"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />{' '}
                          , and any other unit with access to frequent Root
                          abilities can also hinder the Marionette for a few
                          seconds at a tim.;
                        </li>
                      </ul>
                      <li>
                        Any Echomancer with Displacement capabilities can be
                        used to delay Aria or interrupt her Stun (this includes
                        the aforementioned Barriers as they push enemies in
                        their path when you cast them), this includes but isn’t
                        limited to:
                      </li>
                      <ul>
                        <li>
                          <AshCharacter
                            slug="mo-hongxiu"
                            mode="inline"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />{' '}
                          and every other Tank with a ‘Gather’ type Skill.
                        </li>
                        <li>
                          <AshCharacter
                            slug="sambheka"
                            mode="inline"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />{' '}
                          ,{' '}
                          <AshCharacter
                            slug="xiangling"
                            mode="inline"
                            showLabel
                            showTags
                            showIcon
                            enablePopover
                          />{' '}
                          and every Echomancer with Push or Pull Skills, but
                          prefer picking Echomancers who synergise with your
                          team.
                        </li>
                      </ul>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Golden Butterfly Mines</Accordion.Header>
                  <Accordion.Body>
                    <StaticImage
                      src="../../../images/ash/event/sephonis_6.webp"
                      alt="Guides"
                    />
                    <p>
                      As you might have noticed, the battleground is divided
                      into multiple smaller sections, out of which only 3 can be
                      used for your initial positioning. The tiles of these
                      small sections are at times replaced by Golden Butterfly
                      patterns, with the section hosting the most Echomancers
                      being targeted on priority. Once the patterns appear, you
                      are put on a timer, which is represented by a purple
                      outline progressively running around the entire
                      sub-section. Once fully outlined in purple, the
                      sub-section will explode, dealing high damage to any
                      Echomancer standing inside it. While the damage might not
                      be enough to One-Shot your Tanks it is more than enough to
                      wipe your Damage Dealers and Healers if you’re not playing
                      a high VIT+DEF build.
                    </p>
                    <p>To deal with this mechanic, you can opt to either:</p>
                    <ul>
                      <li>
                        Keep all of your Echomancers in different sub-sections,
                        this will require minimal movement and makes it more
                        forgiving even if you fail to escape in time;
                      </li>
                      <li>
                        Or group all of your ranged Echomancers together and
                        have them migrate as a team to another section
                        periodically. It might be easier to deal with Aria as
                        well that way but it isn't necessary.
                      </li>
                    </ul>
                    <p>
                      This Skill is, in itself, not dangerous as you have ample
                      time to dodge it, however any action that locks your
                      Echomancers in place can make it much more threatening.
                      Some of those actions can be Aria’s Stun, or channeled
                      Echomancers Ultimate such as Lorelle’s for example.
                    </p>
                    <p>
                      <strong>Key Echomancers:</strong>
                    </p>
                    <p>
                      There is no need for any specific tool in order to deal
                      with this mechanic, simply avoid locking yourself in place
                      before the Mines are revealed.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>Migration</Accordion.Header>
                  <Accordion.Body>
                    <StaticImage
                      src="../../../images/ash/event/sephonis_7.webp"
                      alt="Guides"
                    />
                    <p>
                      To describe this skill, it only takes three words:
                      ‘Beautiful yet deadly’. After a very brief cutscene,
                      Sephonis rotates herself before indicating two big yellow
                      arrows in opposite directions, and then a few seconds
                      later 4 volleys of tiny Golden Butterflies are released in
                      both directions. They immediately start spreading
                      outwards, rotating counter-clockwise before coming back to
                      Sephonis. Any Echomancer those Butterflies pass through
                      will suffer immense damage, and as dozens of them are
                      released at once you better not let them spread or it is
                      quickly going to lead to a loss or two on your side.
                    </p>
                    <ul>
                      <li>
                        Your best tool against this skill is Projectile
                        Interception, which can be not only found on most
                        Barrier Skills, but also on Echomancers such as Felicio,
                        Lanlan or Cen Ying to name a few who simply make all
                        enemy projectiles vanish upon using their Interception
                        Skills.
                      </li>
                      <li>
                        Alternatively, the tiny butterflies are unable to reach
                        the extremities of the outer sub-sections of the map,
                        making it possible to hide your Rangers and Supports on
                        safe tiles. In the case of Melee units, the butterflies
                        never hit you if you’re standing close to the Boss at a
                        90° angle to the original directions they were sent
                        from.
                      </li>
                    </ul>
                    <p>
                      This skill is arguably one of the easiest to counter, but
                      is also the most important to avoid, so having one or even
                      two Echomancers with Interception Skills is going to make
                      life much easier for most players.
                    </p>
                    <p>
                      <strong>Key Echomancers:</strong>
                    </p>
                    <p>
                      You can’t always afford to move around, and that’s when
                      those Echomancers can save you from an instant game over:
                    </p>
                    <ul className="bigger-margin">
                      <li>
                        <AshCharacter
                          slug="felicio"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        <AshCharacter
                          slug="lanlan"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        <AshCharacter
                          slug="cen-ying"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        <AshCharacter
                          slug="tong"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        and any Echomancer who explicitly mentions in their kit
                        Intercepting, Clearing or Destroying Projectiles. You
                        can wait for the 3 volleys to be sent then immediately
                        make all the butterflies disappear. However, these
                        Echomancers tend to be more fragile, so most of them are
                        best left as backup until the time is right;
                      </li>
                      <li>
                        The majority of Barriers are incapable of blocking all
                        of the tiny Butterflies and are destroyed midway, this
                        is the case for all Barriers and the amount of
                        projectiles they can soak is dependent on their
                        strength.
                      </li>
                      <li>
                        Nonetheless,{' '}
                        <AshCharacter
                          slug="luke"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        remains a near perfect pick here, as you can hit most of
                        the butterflies with his Ultimate then block the
                        stragglers with his Active Skill, or at least cover one
                        side effectively halving the number of butterflies.
                      </li>
                      <li>
                        <AshCharacter
                          slug="su-xiao"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        <AshCharacter
                          slug="longqing"
                          mode="inline"
                          showLabel
                          showTags
                          showIcon
                          enablePopover
                        />{' '}
                        (her Ultimate blocks Incoming Projectiles, but not those
                        already inside), and the previously mentioned Barrier
                        Echomancers can all be used for this mechanic as well.
                        Still, try to prioritize Projectile Destruction to avoid
                        hiccups.
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Clones</Accordion.Header>
                  <Accordion.Body>
                    <StaticImage
                      src="../../../images/ash/event/sephonis_8.webp"
                      alt="Guides"
                    />
                    <p>
                      This ability only occurs after Sephonis revives - three
                      Clones of Sephonis appear in a triangle formation while
                      Sephonis disappears from the fight. She comes back after
                      either all the Clones have been destroyed or after 30
                      seconds have elapsed. This mechanic is without a doubt a
                      DPS race, as the Clones are ticking time-bombs,
                      periodically emitting pulses of Golden Energy around them
                      until they’re defeated and blow up causing massive damage
                      a couple of seconds after Sephonis returns to the fight.
                      Few Echomancers can target all three Clones at once, so
                      it’s important to keep multiple Burst tools available for
                      this situation.
                    </p>
                    <p>
                      <strong>Key Echomancers:</strong>
                    </p>
                    <p>
                      No crazy tricks or anything here, you’re best off keeping
                      your main DPS Ultimate and an Active Skill or two for this
                      mechanic, once Sephonis has recovered.
                    </p>
                    <p>
                      Units like{' '}
                      <AshCharacter
                        slug="cyros"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      <AshCharacter
                        slug="lorelle"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      (best to use her Ultimate a few seconds before the Clones
                      appear in her case),{' '}
                      <AshCharacter
                        slug="lydia"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      or
                      <AshCharacter
                        slug="cen-ying"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      to name a few have very wide AoEs on their Ultimates and
                      can make quick work of the Clones.
                    </p>
                    <p>
                      Corrosion Blast, Explosion of Fire Zones and
                      Electroconduct also work great here, if you’re playing
                      around those synergies.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header>The Final Dance</Accordion.Header>
                  <Accordion.Body>
                    <StaticImage
                      src="../../../images/ash/event/sephonis_9.webp"
                      alt="Guides"
                    />
                    <p>
                      About <strong>6 minutes into the fight</strong>, Sephonis
                      starts dancing with Aria, dealing massive AoE damage to
                      all Echomancers on the field{' '}
                      <strong>and to your Terminal</strong>. If your damage is
                      too lacking, this Dance is synonymous with Game Over in a
                      short few seconds. There is nothing at this point that you
                      can do aside from defeating Sephonis in one last all out
                      attack.
                    </p>
                    <p>
                      <strong>Note:</strong> Even if you remove Aria from play
                      via Corrosion Blast or{' '}
                      <AshCharacter
                        slug="lorelle"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      Ultimate as mentioned above, Sephonis will eventually
                      start dancing by herself anyway.
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="300% Team Examples" />
              <p>
                Let’s go over a few teams. They have varying levels of Engraving
                Stats and Skills but all are capable of beating the Boss.
              </p>
              <p>
                For this first Event, we consider that{' '}
                <strong>
                  Engraving Stats are generally more important than the Skills
                </strong>
                , as early on it's harder to build proper synergies. The
                exceptions being Defensive Skills as those benefit your team
                regardless of your other options, but they might be hard to come
                by.
              </p>
              <p>
                Every Team featured here was tested with{' '}
                <strong>
                  as little Dupes as possible for the 6 Star Echomancers
                </strong>{' '}
                (except for the aid which had a dupe on some occasion) and{' '}
                <strong>no Potential Awakenings</strong> were used.
              </p>
              <p>
                Lastly, before diving in, we’d like to talk about one of the
                unsung Heroes of this guide:{' '}
                <AshCharacter
                  slug="su-xiao"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />
              </p>
              <p>
                <AshCharacter
                  slug="su-xiao"
                  mode="inline"
                  showLabel
                  showTags
                  showIcon
                  enablePopover
                />{' '}
                is an excellent pick for your bench, and fits almost perfectly
                when it comes to learning the fight but also for enhancing your
                Burst when you already know what you’re doing. Her Max HP up +
                Full Heal on Ultimate is already powerful, but when you add to
                that her one time Full Heal on Combat Trait that also comes with
                permanent offensive buffs, a full recharge of Skill Uses AND a
                full Ultimate charge!
              </p>
              <p>
                This enables some double Ultimate + Skill spam during your Burst
                window, dealing millions of damage in a matter of seconds with
                just one DPS, truly excellent for a free character who doesn’t
                even need Dupes.
              </p>
              <p>
                <strong>Note!</strong> Use the TABS below to check different
                teams.
              </p>
              <Tabs
                defaultActiveKey="Team1"
                transition={false}
                id="event-teams"
                className="nav-tabs"
              >
                <Tab
                  eventKey="Team1"
                  title={<>Team #1 - Summoner Team</>}
                  className="with-bg"
                >
                  <h5>Team #1 - Summoner Team</h5>
                  <StaticImage
                    src="../../../images/ash/event/sephonis_10.webp"
                    alt="Guides"
                  />
                  <p>
                    This Team revolves around the Summon synergy, with Cen Ying
                    as Leader to increase her personal damage. Luke and Tong can
                    both be used in her place depending on your available
                    Inspire sources and who your main DPS is.
                  </p>
                  <p>
                    The Memory Traces are composed of three Story rewards,
                    ‘Beneath Deception’, ‘Behind the Curtains’ and ‘Karma’ (you
                    will have all of them by the time you complete Chapter 3 of
                    the Main Story), one SR Trace ‘Muscle Building’ and two SSR
                    gacha Traces namely ‘Bad Soil Bloom’ and ‘Shadows Of The
                    Alley’ (one of them being the Friend/Club aid).
                  </p>
                  <p>
                    With a heavy focus on ATK and MST, and then some DEF + VIT,
                    this Stat spread ensures a strong damage output for the
                    entire duration of the fight at the expense of only having a
                    few Ranged/Summon oriented Skills like ‘Snipe’ and
                    ‘Coordinated Summons’.
                  </p>
                  <p>
                    If you find surviving with this team difficult, opt for a
                    little more VIT or DEF and a little less MST or TRM, or go
                    for the SSR Trace ‘My Vision Is Clear’ instead of ‘Karma’
                    which offers multiple powerful Damage Reduction Skills.
                  </p>
                  <ul className="bigger-margin">
                    <li>
                      <AshCharacter
                        slug="lorelle"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      <AshCharacter
                        slug="freda"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      and{' '}
                      <AshCharacter
                        slug="longqing"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      protect your squishies and keep them healthy, having 2/3
                      or even all 3 out for the first seconds/minutes of the
                      fight where the Corrosive Force deals tremendous damage
                      and your Inspire stacks aren’t maxed out yet helps with
                      the stability of the run.
                    </li>
                    <li>
                      <AshCharacter
                        slug="tong"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      <AshCharacter
                        slug="luke"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      and{' '}
                      <AshCharacter
                        slug="cen-ying"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      can easily dispatch Aria but Roar is also there just in
                      case you’d want an early Barrier to set up your team more
                      easily, if you do not find a use for her in that team do
                      not hesitate to replace her for any other Echomancer that
                      fills a gap in your strategy.
                    </li>
                    <li>
                      The three Physical carries are also excellent to Intercept
                      the tiny Butterflies from Migration, with each of them
                      possessing Projectile Destruction Skills or Barriers. They
                      also do wonders when facing the Clones, since all of their
                      Ultimates can target at least 2 of the Clones at once, and
                      even some of their Active Skills can do that.
                    </li>
                  </ul>
                  <h5>Video</h5>
                  <p>Here's a video showcasing a 300% clear with the team:</p>
                  <Row className="video">
                    <Col xs={12} lg={6}>
                      <YoutubeEmbed embedId="Rd9oDle8brE" />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="Team2"
                  title={<>Team #2 - Corrosion Team</>}
                  className="with-bg"
                >
                  <h5>Team #2 - Corrosion Team</h5>
                  <StaticImage
                    src="../../../images/ash/event/sephonis_11.webp"
                    alt="Guides"
                  />
                  <p>
                    With Lorelle instead of Caroline as a Leader, the Team
                    benefits from improved Defensive Stats, in exchange for a
                    lower damage output. Still, the extremely high damage of the
                    Corrosive Blast proves to be more than enough to make quick
                    work of Sephonis, even at fairly low TRM.
                  </p>
                  <p>
                    The Stat focus is on ATK and TRM, then DEF and VIT. The
                    reason for such high MST comes from having 2 MST Traces
                    which naturally makes MST build up extremely fast even if
                    you pick it only once or twice during your Nexus run.
                  </p>
                  <p>
                    The Traces choices here are a little less F2P friendly, but
                    they all are excellent and highly versatile: ‘Hidden Blade’,
                    ‘Growth’, ‘Emptiness’ and ‘Shadows Of The Alley’ are the SSR
                    gacha Traces, with 3 of them playable in most launch builds,
                    SR Trace ‘Muscle Building’ is here once again, as an amazing
                    pick for Caroline and lastly ‘Karma’ for a solid mix of
                    offense and defense built into one Trace.
                  </p>
                  <p>
                    The levels of the Traces are fairly low, sitting at 40 for
                    most, meaning that a lot more Stats can be obtained by
                    pushing the Traces to a higher level.
                  </p>
                  <p>
                    This time around we focus on Offensive Main and Sub Skills,
                    mostly Ranged related to push Caroline and Gina’s damage
                    higher and make the team slightly less reliant on the
                    Corrosive Blast.
                  </p>
                  <ul className="bigger-margin">
                    <li>
                      <AshCharacter
                        slug="lorelle"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      <AshCharacter
                        slug="freda"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      and{' '}
                      <AshCharacter
                        slug="longqing"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      serve the same purpose of keeping your team alive, but in
                      addition to those three we now have{' '}
                      <AshCharacter
                        slug="caroline"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      whose ‘Goodspeed Potion’ keeps the team topped up at all
                      times thanks to its infinite Skill uses.
                    </li>
                    <li>
                      The Corrosive Blast can get rid of Aria once her health is
                      low enough, and in the case that it's not possible we can
                      reposition{' '}
                      <AshCharacter
                        slug="caroline"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      (the most likely target) for the entire fight duration
                      thanks to his short CDs and very long attack range.
                      Alternatively,{' '}
                      <AshCharacter
                        slug="sephonis"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      can apply a small Root that refreshes multiple times to
                      keep the Marionette at bay.
                    </li>
                    <li>
                      There isn’t much Projectile Interception in this team, so
                      we bring{' '}
                      <AshCharacter
                        slug="felicio"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      in case repositioning our units is impossible. You can
                      also keep one of your TRM Skill uses to destroy the
                      Projectiles once throughout the fight.
                    </li>
                    <li>
                      By the time we reach the Clones, our Corrosion Echomancers
                      are fully amped up and they no longer pose a threat to our
                      team, just make sure to use your Skills to hit as many
                      targets as you can with{' '}
                      <AshCharacter
                        slug="gina"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />
                    </li>
                  </ul>
                  <h5>Video</h5>
                  <p>Here's a video showcasing a 300% clear with the team:</p>
                  <Row className="video">
                    <Col xs={12} lg={6}>
                      <YoutubeEmbed embedId="zbKi75YIbZk" />
                    </Col>
                  </Row>
                </Tab>
                <Tab
                  eventKey="Team3"
                  title={<>Team #3 - Fire Team</>}
                  className="with-bg"
                >
                  <h5>Team #3 - Fire Team</h5>
                  <StaticImage
                    src="../../../images/ash/event/sephonis_12.webp"
                    alt="Guides"
                  />
                  <p>
                    Based on the Fire Zone synergy, this team requires high
                    Stats and multiple Defensive Skills to express itself. Here
                    we focus ATK + MST and then we aim to push both DEF and VIT,
                    leaving TRM untouched as much as we can.
                  </p>
                  <p>
                    The reason for that is the absence of Lorelle, which as a
                    result makes your entire Team take more damage. However, in
                    turn this team boasts extremely high Burst during Phase 2
                    where Cyros and Lydia turn into DPS monsters.
                  </p>
                  <p>
                    This time we go for the SSR Traces ‘Nightmare’ to reduce the
                    overall damage and stack Inspire with Longqing or Tian Ruo,
                    ‘Dusk Radiance’ is brought for ‘Flame Infusion’ (and
                    ‘Weakness Mark’ if your Mo Hongxiu has 1 Dupe) and as a
                    strong source of VIT, ‘My Vision Is Clear’ brings even more
                    DEF and some Defensive Skills, while ‘Emptiness’ further
                    improves our Melee’s survival rate. Lastly, ‘Shadows Of The
                    Alley’ brings more of the much needed Inspire stacks.
                  </p>
                  <ul className="bigger-margin">
                    <li>
                      <AshCharacter
                        slug="lorelle"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      <AshCharacter
                        slug="freda"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      and{' '}
                      <AshCharacter
                        slug="su-xiao"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      are the main sources of Healing and Defense on the side of
                      the Echomancers, with Su Xiao coming in later in the fight
                      to provide an Max HP increase with her Ultimate and can be
                      used to further amplify one of your carries’ damage
                      output.
                    </li>
                    <li>
                      <AshCharacter
                        slug="mo-hongxiu"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      and{' '}
                      <AshCharacter
                        slug="roar"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      are both excellent to deal with Aria, and{' '}
                      <AshCharacter
                        slug="cyros"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      can also help thanks to his frequent Ultimates and his
                      many Active Skill uses.
                    </li>
                    <li>
                      While{' '}
                      <AshCharacter
                        slug="mo-hongxiu"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      and{' '}
                      <AshCharacter
                        slug="roar"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      have tools to deal with the Migration, quick repositioning
                      and{' '}
                      <AshCharacter
                        slug="felicio"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      are still better at the job. On top of that, Felicio will
                      nicely boost Cyros or Roar’s damage for a short time after
                      Intercepting.
                    </li>
                    <li>
                      The Clones melt under{' '}
                      <AshCharacter
                        slug="cyros"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      Ultimate, or his Active Skill, or even his Basic Attacks.
                      <AshCharacter
                        slug="lydia"
                        mode="inline"
                        showLabel
                        showTags
                        showIcon
                        enablePopover
                      />{' '}
                      is also capable of shredding through these Clones as if
                      they were made of paper. In general during Phase 2, losing
                      with this team won’t be due to a lack of Firepower as it
                      can make up for its slower clear speed during Phase 1.
                    </li>
                  </ul>
                  <h5>Video</h5>
                  <p>Here's a video showcasing a 300% clear with the team:</p>
                  <Row className="video">
                    <Col xs={12} lg={6}>
                      <YoutubeEmbed embedId="lr3_gw5tqr8" />
                    </Col>
                  </Row>
                </Tab>
              </Tabs>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h5>Jump to</h5>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Boss Challenge Lite Edition</a>
                </li>
                <li>
                  <a href="#section-2">200% and 250% Team Examples</a>
                </li>
                <li>
                  <a href="#section-3">Key Mechanics</a>
                </li>
                <li>
                  <a href="#section-4">300% Team Examples</a>
                </li>
              </ul>
            </ScrollspyNav>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AshGuidesBossSepho;

export const Head: React.FC = () => (
  <Seo
    title="Boss Challenge: Sephonis (300%) | Ash Echoes | Prydwen Institute"
    description="A dedicated guide for the Boss Challenge: Sephonis in Ash Echoes. Beat the 300% difficulty with ease with our guide!"
    game="ash"
  />
);
